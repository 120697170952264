import AccountCache from '../../account/api/cache';

import CartCache from './cache';
import { request } from './request';
import { CartBackendResponseError } from './errors';
import { getCartId } from './getCartId';

const accountCache = new AccountCache();
const cartCache = new CartCache();

const query = `query($cartId: String!) {
  cart(cart_id: $cartId) {
    items {
      uid
      quantity
      product {
        name
        url_key
        small_image {
          url
          label
        }
      }
      ... on SimpleCartItem {
        customizable_options {
          uid: customizable_option_uid
          label
          values {
            uid: customizable_option_value_uid
            value
            label
          }
        }
      }
    }
  }
}`;

const getContent = async () => {
  const cartId = await getCartId();

  const content = await cartCache.getContent();
  if (content) {
    return content;
  }

  const accountId = await accountCache.getId();

  let response;

  try {
    response = await request(
      query,
      {
        cartId,
      },
      accountId,
      async (res) => {
        if (res.status === 404) {
          await cartCache.clear();
          return [];
        }
        return undefined;
      },
    );
  } catch (e) {
    if (e instanceof CartBackendResponseError) {
      await cartCache.clear();
      if (accountId) {
        await accountCache.clear();
      }
    }
    throw e;
  }

  const {
    cart: {
      items,
    },
  } = response;

  await cartCache.setContent(items);

  return items;
};

// eslint-disable-next-line import/prefer-default-export
export { getContent };
