import IdContentCache from '../../id-content-cache';
import { DefaultLifetime } from '../../cache';

class CartCache extends IdContentCache {
  constructor() {
    super(
      'cart',
      2,
      DefaultLifetime,
    );
  }
}

export default CartCache;
