import { useQuery } from 'react-query';

import { useLogout } from '../account/useLogout';
import { handleLogout } from './api/handleLogout';

const useLogoutShopEvent = (client) => {
  const logout = useLogout(client);

  return useQuery(['shop-event', 'logout'], () => handleLogout(
    () => logout.mutate(),
  ), {
    retry: false,
    refetchInterval: 1000 * 10,
  });
};

// eslint-disable-next-line import/prefer-default-export
export { useLogoutShopEvent };
