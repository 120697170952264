import { useQuery } from 'react-query';

import { getAccountId } from './api/getAccountId';

const useAccountIdQueryKey = ['account', 'id'];

const useAccountId = () => useQuery(useAccountIdQueryKey, getAccountId);

// eslint-disable-next-line import/prefer-default-export
export { useAccountIdQueryKey, useAccountId };
