/* eslint-disable max-classes-per-file */

class LogoutProcessError extends Error {
  constructor(message) {
    super(message);
    this.name = 'LogoutError';
  }
}

class LogoutRequestError extends Error {
  constructor(message) {
    super(message);
    this.name = 'LogoutUnavailableError';
  }
}

class LoginRequestError extends Error {
  constructor(message) {
    super(message);
    this.name = 'LoginRequestError';
  }
}

class LoginResponseError extends Error {
  constructor(message) {
    super(message);
    this.name = 'LoginResponseError';
  }
}

class LoginProcessError extends Error {
  constructor(message) {
    super(message);
    this.name = 'LoginProcessError';
  }
}

class LoginAlreadyLoggedinError extends Error {
  constructor(message) {
    super(message);
    this.name = 'LoginAlreadyLoggedinError';
  }
}

class LoginUnauthorizedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'LoginUnauthorizedError';
  }
}

export {
  LogoutRequestError,
  LogoutProcessError,
  LoginRequestError,
  LoginResponseError,
  LoginProcessError,
  LoginAlreadyLoggedinError,
  LoginUnauthorizedError,
};
