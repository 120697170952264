import { useQuery } from 'react-query';

import { useWishlistQueryKey } from '../wishlist/useWishlist';

import { handleWishlistContentRefresh } from './api/handleWishlistUpdate';

const useWishlistRefreshShopEvent = (client) => useQuery(['shop-event', 'wishlist-refresh'], () => handleWishlistContentRefresh(
  () => client.invalidateQueries(useWishlistQueryKey),
), {
  retry: false,
  refetchInterval: 1000 * 10,
});

// eslint-disable-next-line import/prefer-default-export
export { useWishlistRefreshShopEvent };
