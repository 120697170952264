import { useMutation } from 'react-query';

import { logout } from './api/logout';
import { useAccountIdQueryKey } from './useAccountId';
import { useWishlistQueryKey } from '../wishlist/useWishlist';
import { useCartQueryKey } from '../cart/useCart';
import { useCartIdQueryKey } from '../cart/useCartId';

const useLogout = (queryClient) => useMutation(() => logout(), {
  retry: false,
  onMutate: async () => {
    await queryClient.cancelQueries(useCartQueryKey);
    await queryClient.cancelQueries(useWishlistQueryKey);
  },
  onSettled: () => {
    queryClient.invalidateQueries(useAccountIdQueryKey);
    queryClient.invalidateQueries(useCartIdQueryKey);
    queryClient.invalidateQueries(useCartQueryKey);
    queryClient.invalidateQueries(useWishlistQueryKey);
  },
});

// eslint-disable-next-line import/prefer-default-export
export { useLogout };
