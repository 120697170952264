import AccountCache from './cache';
import WishlistCache from '../../wishlist/api/cache';
import CartCache from '../../cart/api/cache';
import { LogoutProcessError, LogoutRequestError } from './errors';

const accountCache = new AccountCache();
const cartCache = new CartCache();
const wishlistCache = new WishlistCache();

const resetState = () => Promise.all([
  accountCache.clear(),
  wishlistCache.clear(),
  cartCache.clear(),
]);

const logout = async () => {
  // tell the account service to log out
  const logoutResponse = await fetch(
    new Request(process.env.GATSBY_SERVICE_ACCOUNT_LOGOUT_URL), {
      method: 'GET',
      credentials: 'include', // include cookies in request
    },
  );

  // Obvious errors like network errors or unexpected response codes should result in a LogoutRequestError
  if (!logoutResponse.ok || logoutResponse.status !== 200) {
    throw new LogoutRequestError('logout service request failed');
  }

  await logoutResponse.json();

  const accountId = await accountCache.getId();
  // if there is no account id / customer token to revoke, we are done here
  if (!accountId) {
    await resetState();
    return;
  }

  // revoke the account id / customer token
  const revokeResponse = await fetch(
    new Request(process.env.GATSBY_SERVICE_ACCOUNT_GRAPHQL_BASEURL), {
      method: 'POST',
      headers: new Headers({
        Store: process.env.GATSBY_SERVICE_ACCOUNT_STORECODE,
        'content-type': 'application/json',
        authorization: `Bearer ${accountId}`,
      }),
      body: JSON.stringify({
        query: 'mutation { revokeCustomerToken { result } }',
      }),
    },
  );

  // Obvious errors like network errors or unexpected response codes should result in a LogoutRequestError
  if (!revokeResponse.ok || revokeResponse.status !== 200) {
    throw new LogoutRequestError('revoke-token request failed');
  }

  const { data, errors } = await revokeResponse.json();

  // there were graphql errors but the response states that the customer token just wasn't available anymore
  // the request had the goal to revoke the customer token, which is the same as not having the token anymore
  if (errors && data?.revokeCustomerToken === null) {
    await resetState();
    return;
  }

  // any other graphql error should result in a LogoutProcessError
  if (errors) {
    throw new LogoutProcessError(`revoke-token graphql error: ${errors[0].message}`);
  }

  const { revokeCustomerToken: { result } } = data;

  // the api doesn't state a reason but something didn't work
  if (result === false) {
    throw new LogoutProcessError('revoke-token action not successful');
  }

  await resetState();
};

// eslint-disable-next-line import/prefer-default-export
export { logout };
