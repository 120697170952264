import { useQuery } from 'react-query';

import { useCartQueryKey } from '../cart/useCart';

import { handleCartContentRefresh } from './api/handleCartUpdate';

const useCartRefreshShopEvent = (client) => useQuery(['shop-event', 'cart-refresh'], () => handleCartContentRefresh(
  () => client.invalidateQueries(useCartQueryKey),
), {
  retry: false,
  refetchInterval: 1000 * 10,
});

// eslint-disable-next-line import/prefer-default-export
export { useCartRefreshShopEvent };
