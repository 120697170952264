import cache from '@luudjanssen/localforage-cache';

const DefaultLifetime = (60 * 1000 * 60 * 24 * 3) - (1000 * 60 * 5); // 3 days - 5 Minutes

class Cache {
  instance;

  constructor(name, version, expiration = undefined) {
    this.instance = cache.createInstance({
      name: `${name}-v${version}`,
      defaultExpiration: expiration,
    });
  }

  async _expire(key) {
    const expirationKey = cache._expirationKey(key);
    const expires = await this.instance.getItem(expirationKey);
    if (expires === null) {
      return;
    }

    if (cache._hasExpired(expires)) {
      await this.instance.clear();
    }
  }

  async setItem(key, value) {
    await this._expire(key);
    return this.instance.setItem(key, value);
  }

  async removeItem(key) {
    await this._expire(key);
    return this.instance.removeItem(key);
  }

  async getItem(key) {
    await this._expire(key);
    return this.instance.getItem(key);
  }

  clear() {
    return this.instance.clear();
  }
}

export {
  Cache,
  DefaultLifetime,
};
