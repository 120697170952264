import { useQuery } from 'react-query';

import { getCartId } from './api/getCartId';

const useCartIdQueryKey = ['cart', 'id'];

const useCartId = () => useQuery(useCartIdQueryKey, getCartId);

// eslint-disable-next-line import/prefer-default-export
export { useCartIdQueryKey, useCartId };
