import AccountCache from '../../account/api/cache';

import CartCache from './cache';
import { request } from './request';
import { CartBackendResponseError } from './errors';

const accountCache = new AccountCache();
const cartCache = new CartCache();

const createCart = async () => {
  const accountId = await accountCache.getId();

  let response;

  try {
    response = await request(
      'mutation { createEmptyCart }',
      undefined,
      accountId,
    );
  } catch (e) {
    if (e instanceof CartBackendResponseError) {
      await cartCache.clear();
      if (accountId) {
        await accountCache.clear();
      }
    }
    throw e;
  }

  const {
    createEmptyCart: newCartId,
  } = response;

  await cartCache.setId(newCartId);

  return newCartId;
};

// eslint-disable-next-line import/prefer-default-export
export { createCart };
