import ShopEvent from './shopEvent';

async function handleLogout(onFired) {
  const event = new ShopEvent(
    'logout',
    process.env.GATSBY_SHOPEVENT_COOKIE_DOMAIN,
    onFired,
  );

  await event.handle();
}

// eslint-disable-next-line import/prefer-default-export
export { handleLogout };
