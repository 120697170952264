import { Cache } from './cache';

class IdContentCache extends Cache {
  setId(data) {
    return this.setItem('id', data);
  }

  removeId() {
    return this.removeItem('id');
  }

  getId() {
    return this.getItem('id');
  }

  setContent(data) {
    return this.setItem('content', data);
  }

  removeContent() {
    return this.removeItem('content');
  }

  getContent() {
    return this.getItem('content');
  }
}

export default IdContentCache;
