import Cookies from 'js-cookie';

const NAME_PREFIX = 'fe_event_';

class ShopEvent {
  name;

  domain;

  handler;

  constructor(name, domain, handler) {
    this.name = name;
    this.domain = domain;
    this.handler = handler;
  }

  needsHandling() {
    if (!this.name || !this.domain) {
      return false;
    }

    const eventContent = Cookies.get(`${NAME_PREFIX}${this.name}`);
    return eventContent === '1';
  }

  async handle(handler) {
    if (!this.needsHandling()) {
      return false;
    }

    if (this.handler) {
      await this.handler();
    }

    if (handler) {
      await handler();
    }

    Cookies.remove(`${NAME_PREFIX}${this.name}`, { path: '/', domain: this.domain });
    return true;
  }
}

export default ShopEvent;
