import ShopEvent from './shopEvent';
import WishlistCache from '../../wishlist/api/cache';

const event = new ShopEvent(
  'wishlist_content_refresh',
  process.env.GATSBY_SHOPEVENT_COOKIE_DOMAIN,
);

const wishlistCache = new WishlistCache();

async function handleWishlistContentRefresh(onFired) {
  await event.handle(async () => {
    await wishlistCache.removeContent();
    onFired();
  });
}

// eslint-disable-next-line import/prefer-default-export
export { handleWishlistContentRefresh };
