import { WishlistBackendResponseError } from './errors';

const request = async (query, variables, authToken) => {
  const headers = {
    Store: process.env.GATSBY_SERVICE_WISHLIST_STORECODE,
    'content-type': 'application/json',
  };

  if (authToken) {
    headers.authorization = `Bearer ${authToken}`;
  }

  const res = await fetch(
    new Request(process.env.GATSBY_SERVICE_WISHLIST_BASEURL), {
      method: 'POST',
      headers: new Headers(headers),
      body: JSON.stringify({
        query,
        variables,
      }),
    },
  );

  if (!res.ok || res.status !== 200) {
    throw new Error('wishlist service: invalid status');
  }

  const { data, errors } = await res.json();
  if (errors) {
    const msg = `wishlist service graphql error: ${errors[0].message}`;
    // category "graphql" == graphql syntax error
    if (errors[0].extensions?.category === 'graphql') {
      throw new Error(msg);
    }
    throw new WishlistBackendResponseError(msg);
  }

  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { request };
