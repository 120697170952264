exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-templates-all-brands-by-category-js": () => import("./../../../src/templates/all-brands-by-category.js" /* webpackChunkName: "component---src-templates-all-brands-by-category-js" */),
  "component---src-templates-all-brands-js": () => import("./../../../src/templates/all-brands.js" /* webpackChunkName: "component---src-templates-all-brands-js" */),
  "component---src-templates-brand-collection-detail-js": () => import("./../../../src/templates/brand-collection-detail.js" /* webpackChunkName: "component---src-templates-brand-collection-detail-js" */),
  "component---src-templates-brand-collection-list-js": () => import("./../../../src/templates/brand-collection-list.js" /* webpackChunkName: "component---src-templates-brand-collection-list-js" */),
  "component---src-templates-brand-occasion-detail-js": () => import("./../../../src/templates/brand-occasion-detail.js" /* webpackChunkName: "component---src-templates-brand-occasion-detail-js" */),
  "component---src-templates-brand-product-js": () => import("./../../../src/templates/brand-product.js" /* webpackChunkName: "component---src-templates-brand-product-js" */),
  "component---src-templates-brands-js": () => import("./../../../src/templates/brands.js" /* webpackChunkName: "component---src-templates-brands-js" */),
  "component---src-templates-catalog-list-js": () => import("./../../../src/templates/catalog-list.js" /* webpackChunkName: "component---src-templates-catalog-list-js" */),
  "component---src-templates-catalog-order-js": () => import("./../../../src/templates/catalog-order.js" /* webpackChunkName: "component---src-templates-catalog-order-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-cms-js": () => import("./../../../src/templates/cms.js" /* webpackChunkName: "component---src-templates-cms-js" */),
  "component---src-templates-compare-js": () => import("./../../../src/templates/compare.js" /* webpackChunkName: "component---src-templates-compare-js" */),
  "component---src-templates-detective-js": () => import("./../../../src/templates/detective.js" /* webpackChunkName: "component---src-templates-detective-js" */),
  "component---src-templates-filter-landingpage-js": () => import("./../../../src/templates/filter-landingpage.js" /* webpackChunkName: "component---src-templates-filter-landingpage-js" */),
  "component---src-templates-glossary-jewellery-js": () => import("./../../../src/templates/glossary-jewellery.js" /* webpackChunkName: "component---src-templates-glossary-jewellery-js" */),
  "component---src-templates-glossary-watch-js": () => import("./../../../src/templates/glossary-watch.js" /* webpackChunkName: "component---src-templates-glossary-watch-js" */),
  "component---src-templates-guestbook-js": () => import("./../../../src/templates/guestbook.js" /* webpackChunkName: "component---src-templates-guestbook-js" */),
  "component---src-templates-latest-products-js": () => import("./../../../src/templates/latest-products.js" /* webpackChunkName: "component---src-templates-latest-products-js" */),
  "component---src-templates-magento-iframe-js": () => import("./../../../src/templates/magento-iframe.js" /* webpackChunkName: "component---src-templates-magento-iframe-js" */),
  "component---src-templates-news-list-js": () => import("./../../../src/templates/news-list.js" /* webpackChunkName: "component---src-templates-news-list-js" */),
  "component---src-templates-news-single-js": () => import("./../../../src/templates/news-single.js" /* webpackChunkName: "component---src-templates-news-single-js" */),
  "component---src-templates-product-single-js": () => import("./../../../src/templates/product-single.js" /* webpackChunkName: "component---src-templates-product-single-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-service-contact-js": () => import("./../../../src/templates/service-contact.js" /* webpackChunkName: "component---src-templates-service-contact-js" */)
}

