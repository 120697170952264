import { useQuery } from 'react-query';

import { useCartQueryKey } from '../cart/useCart';
import { useCartIdQueryKey } from '../cart/useCartId';

import { handleCartComplete } from './api/handleCartComplete';

const useCartCompleteShopEvent = (client) => useQuery(['shop-event', 'cart-complete'], () => handleCartComplete(
  () => {
    client.invalidateQueries(useCartIdQueryKey);
    client.invalidateQueries(useCartQueryKey);
  },
), {
  retry: false,
  refetchInterval: 1000 * 10,
});

// eslint-disable-next-line import/prefer-default-export
export { useCartCompleteShopEvent };
