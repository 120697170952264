import ShopEvent from './shopEvent';
import CartCache from '../../cart/api/cache';

const event = new ShopEvent(
  'cart_content_refresh',
  process.env.GATSBY_SHOPEVENT_COOKIE_DOMAIN,
);

const cartCache = new CartCache();

async function handleCartContentRefresh(onFired) {
  await event.handle(async () => {
    await cartCache.removeContent();
    onFired();
  });
}

// eslint-disable-next-line import/prefer-default-export
export { handleCartContentRefresh };
