import { Cache, DefaultLifetime } from '../../cache';

class AccountCache extends Cache {
  constructor() {
    super(
      'account',
      2,
      DefaultLifetime,
    );
  }

  setId(data) {
    return this.setItem('id', data);
  }

  removeId() {
    return this.removeItem('id');
  }

  getId() {
    return this.getItem('id');
  }
}

export default AccountCache;
