import CartCache from './cache';

import { createCart } from './createCart';

const cart = new CartCache();

const getCartId = async () => {
  let cartId = await cart.getId();
  if (!cartId) {
    cartId = await createCart();
  }

  return cartId;
};

// eslint-disable-next-line import/prefer-default-export
export { getCartId };
