import IdContentCache from '../../id-content-cache';
import { DefaultLifetime } from '../../cache';

class WishlistCache extends IdContentCache {
  constructor() {
    super(
      'wishlist',
      2,
      DefaultLifetime,
    );
  }
}

export default WishlistCache;
