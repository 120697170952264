import { useQuery } from 'react-query';
import { getContent } from './api/getContent';

const useWishlistQueryKey = ['account', 'wishlist', 'content'];

const useWishlist = () => useQuery(useWishlistQueryKey, getContent, {
  retry: false,
});

export { useWishlistQueryKey, useWishlist };
