import { useQuery } from 'react-query';

import { getContent } from './api/getContent';

const useCartQueryKey = ['cart', 'content'];

const useCart = (options = {}, keySuffix) => {
  const key = [...useCartQueryKey];
  if (keySuffix?.length > 0) {
    key.push(keySuffix);
  }

  return useQuery(key, getContent, {
    ...options,
    retry: false,
  });
};

export { useCartQueryKey, useCart };
