import { CartBackendResponseError } from './errors';

const request = async (
  query,
  variables,
  authToken,
  responseHandler = async () => {},
) => {
  const headers = {
    Store: process.env.GATSBY_SERVICE_CART_STORECODE,
    'content-type': 'application/json',
  };

  if (authToken) {
    headers.authorization = `Bearer ${authToken}`;
  }

  const res = await fetch(
    new Request(process.env.GATSBY_SERVICE_CART_BASEURL), {
      method: 'POST',
      headers: new Headers(headers),
      body: JSON.stringify({
        query,
        variables,
      }),
    },
  );

  const customHandlerResult = await responseHandler(res);
  if (customHandlerResult !== undefined) {
    return customHandlerResult;
  }

  if (!res.ok || res.status !== 200) {
    throw new Error('cart service: invalid status');
  }

  const { data, errors } = await res.json();
  if (errors) {
    const msg = `cart service graphql error: ${errors[0].message}`;
    // category "graphql" == graphql syntax error
    if (errors[0].extensions?.category === 'graphql') {
      throw new Error(msg);
    }
    throw new CartBackendResponseError(msg);
  }

  return data;
};

// eslint-disable-next-line import/prefer-default-export
export { request };
