import AccountCache from '../../account/api/cache';

import WishlistCache from './cache';
import { request } from './request';
import { WishlistBackendResponseError } from './errors';

const accountCache = new AccountCache();
const wishlistCache = new WishlistCache();

const query = `query {
  customer {
    wishlists {
      id
      items_v2 {
        items {
          id
          quantity
          product {
            url_key
            name
            small_image {
              url
              label
            }
          }
        }
      }
    }
  }
}`;

const getContent = async () => {
  const accountId = await accountCache.getId();
  if (!accountId) {
    return [];
  }

  const content = await wishlistCache.getContent();
  if (Array.isArray(content)) {
    return content;
  }

  let response;

  try {
    response = await request(query, undefined, accountId);
  } catch (e) {
    if (e instanceof WishlistBackendResponseError) {
      await wishlistCache.clear();
      await accountCache.clear();
    }
    throw e;
  }

  const {
    customer: {
      wishlists,
    },
  } = response;

  if (wishlists.length === 0) {
    await wishlistCache.setContent([]);
    return [];
  }

  let id = await wishlistCache.getId();
  if (!id) {
    id = wishlists[0].id;
    await wishlistCache.setId(id);
  }

  const items = wishlists.find((w) => w.id === id)?.items_v2?.items;

  await wishlistCache.setContent(items ?? []);
  return items;
};

// eslint-disable-next-line import/prefer-default-export
export { getContent };
