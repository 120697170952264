import { useQueryClient } from 'react-query';

import { useLogoutShopEvent } from '../../lib/service/shopEvent/logout';
import { useCartRefreshShopEvent } from '../../lib/service/shopEvent/cartContentRefresh';
import { useCartCompleteShopEvent } from '../../lib/service/shopEvent/cartComplete';
import { useWishlistRefreshShopEvent } from '../../lib/service/shopEvent/wishlistContentRefresh';

// eslint-disable-next-line react/prop-types
const Page = ({ children }) => {
  const queryClient = useQueryClient();

  useLogoutShopEvent(queryClient);
  useCartRefreshShopEvent(queryClient);
  useCartCompleteShopEvent(queryClient);
  useWishlistRefreshShopEvent(queryClient);

  return children;
};

export default Page;
